import { useEffect, useContext, useState } from 'react'
import LoginModal from "./LoginModal"
import Header from "./Header"
import Flash from "./Flash"
import Content from "./Content"
import Footer from "./Footer"

import { readClientToken } from "../services/backend"
import { useAppDispatch } from "../hooks"
import { backendApi, waitToFinish, useValidateTokenMutation, useLazyGetPoliciesQuery } from "../services/backendApi"
import { uiSetVisible } from "../renderer/uiSlice"
import { setCurrentUser } from "../renderer/currentUserSlice"
import { usePageContext } from "../renderer/usePageContext"
import { erasePolicies } from "../renderer/policiesSlice"
import { ReactReduxContext } from 'react-redux'
import { TopBarContextProvider } from "./TopBar"

import { internLog } from "../lib/Util"

import { isPublicOrg, IfPoliciesLoaded } from "../lib/Auth"

function LoadingProgress() {
  return(
    <div className="page-spinner">
      ...
    </div>
  )
}

// Fucking Magic
function onWithDefault(dispatch, callback, args) {
  if (callback) {
    callback(...args)
  }
}

function Everything({ children, onLogin, onLoadUser, onLogout, isLoadingComponent, skipPolicyLoading }) {
  const dispatch = useAppDispatch()
  const { store } = useContext(ReactReduxContext)
  const pageContext = usePageContext()
  const { configParams } = pageContext.config
  const publicOrg = isPublicOrg(pageContext)

  const [isReady, setIsReady] = useState(false)
  const [getPolicies, policiesResult] = useLazyGetPoliciesQuery()
  const { isLoading } = policiesResult

  const loadPolicies = (currentUser, onLoadPolicies) => {
    getPolicies(configParams).unwrap().then(
      (success) => {
        if (onLoadPolicies) {
          onLoadPolicies(store, dispatch, pageContext, currentUser)
        }

        setIsReady(true)
        dispatch(uiSetVisible({ elementName: 'LoadComplete', visible: true }))
      }, (reject) => {
        setIsReady(true)
        dispatch(uiSetVisible({ elementName: 'LoadComplete', visible: true }))
      }
    )
  }

  const globalOnLoadUser = (userData) => {
    loadPolicies(userData, onLoadUser)
  }

  const globalOnLogin = (userData) => {
    dispatch(backendApi.util.resetApiState())

    loadPolicies(userData, onLogin)
  }

  const globalOnLogout = () => {
    dispatch(setCurrentUser({ origin: 'logout' }))

    if (!publicOrg) {
      dispatch(erasePolicies())
    }

    dispatch(backendApi.util.resetApiState())

    loadPolicies(null, onLogout)
  }

  const loadCurrentUserFromLocalStorage = () => {
    const newCurrentUser = readClientToken()
    internLog(newCurrentUser, "Loading currentUser from localStorage...")
    dispatch(setCurrentUser(newCurrentUser))
    globalOnLoadUser(newCurrentUser)
  }

  useEffect(() => {
    window.addEventListener('storage', loadCurrentUserFromLocalStorage)

    loadCurrentUserFromLocalStorage()
  }, [])

  // <Flash />
  
  const showLoading = isLoading || !isReady

  return(
    <TopBarContextProvider>
      <div id="everything" className="ui">
        <LoginModal onLogin={globalOnLogin} />
        <Header onLogout={globalOnLogout} isLoadingUser={showLoading} />
        <div id="content" className="content container">
          <IfPoliciesLoaded forceYes={skipPolicyLoading} elseComponent={showLoading ? (isLoadingComponent || <LoadingProgress/>) : ' '}>
            <div className="aeroport themes">
              { children }
            </div>
          </IfPoliciesLoaded>
        </div>
        <Footer />
      </div>
    </TopBarContextProvider>
  )
}

export default Everything

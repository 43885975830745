import { createApi } from "@reduxjs/toolkit/query/react"
import type {
  ArticleList,
  ArticleLtyState,
  LetsState,
  ReadMarksState,
  User,
  UserContributionsState
} from './types'
import AppConfig from "../config/AppConfig"
import optionallyAuthorizedQuery from "./optionallyAuthorizedQuery"

const BackendApiURL = AppConfig.backend.url

function buildURL({ url, params }) {
  const rootURL = BackendApiURL + url
  if (params) {
    return rootURL + '?' + new URLSearchParams(params)
  } else {
    return rootURL
  }
}

function cleanParams(params) {
  const cleanedParams = {}

  if (params) {
    Object.keys(params).forEach((key) => {
      const value = params[key]
      if ((value !== null) && (typeof value !== 'undefined')) {
        cleanedParams[key] = value
      }
    })
  }

  return cleanedParams
}

function serverQueryURL({ url, pageContext, params = {}, noTenant, overrideTenant }) {
  const { cacheVersion, tenant } = pageContext

  let fullUrl = ''
  if (!noTenant) {
    fullUrl += ('/:' + (tenant || 'en'))
  }

  fullUrl += ('/' + url)

  const fullParams = {
    ...cleanParams(params),
    c: cacheVersion
  }

  return buildURL({
    url: fullUrl,
    params: fullParams
  })
}

// https://www.w3.org/TR/baggage/
function makeOTLPBaggage(obj) {
  let valueString = '';
  for (const [key, value] of Object.entries(obj)) {
    const keyValueString = encodeURIComponent(key).concat('=').concat(encodeURIComponent(value));
    valueString = valueString.concat(keyValueString);
  }
  return valueString;
}

function backendQuery(argFn) {
  const serverQueryFn = async (arg, api, extraOptions, baseQuery) => {
    const state = api.getState()
    const pageContext = state.pageContext

    const { noTenant, onComplete } = (extraOptions || {})

    const {
      url,
      method,
      body,
      headers,
      params,
      overrideTenant
    } = argFn(arg)

    const baggage = {
      'app.req_path': pageContext.urlPathname
    }
    const headersWithBaggage = baggage ? Object.assign({}, headers || {}, {
      'baggage': makeOTLPBaggage(baggage)
    }) : headers

    let result = null
    try {
      result = await baseQuery({
        url: serverQueryURL({
          url,
          pageContext,
          params,
          noTenant,
          overrideTenant
        }),
        method: (method || 'GET'),
        body,
        headers: headersWithBaggage,
        credentials: "include"
      })
    } catch(error) {
      return { error }
    }

    const { data } = result

    if (onComplete) {
      return onComplete(result, state)
    } else {
      return result
    }
  }

  return serverQueryFn
}

export const backendApi = createApi({
  reducerPath: 'backendApi',
  baseQuery: optionallyAuthorizedQuery(BackendApiURL),
  tagTypes: [
    'AdminInvitesList',
    'Article',
    'ArticleInvitations',
    'ArticleList',
    'ArticleLty',
    'ArticleLtyList',
    'Inbox',
    'InvitedUsers',
    'Policies',
    'ReadMarks',
    'UnreadCount',
    'UserActivity',
    'UserProfile',
    'UserSearch',
  ],
  endpoints: (builder) => ({
    // admin/invites
    getAdminInvites: builder.query({
      providesTags: ['AdminInvitesList'],
      queryFn: backendQuery(() => ({
        url: 'admin/invites'
      }))
    }),
    postAdminInviteByEmail: builder.mutation({
      invalidatesTags: ['AdminInvitesList'],
      queryFn: backendQuery(({ email }) => ({
        url: "admin/invites",
        method: "POST",
        body: {
          email: email
        }
      }))
    }),

    // articles_list
    getArticleListBySid: builder.query<ArticleList, string>({
      providesTags: ['ArticleList'],
      queryFn: backendQuery(({ listId }) => ({
        url: 'article_lists/' + listId
      }))
    }),
    postAddArticleToList: builder.mutation({
      invalidatesTags: ['ArticleList'],
      queryFn: backendQuery(({ sid, articleSid }) => ({
        url: 'article_lists/' + sid + '/add',
        method: "POST",
        body: {
          sid: articleSid
        }
      }))
    }),

    // article_lty,
    getArticleLtyList: builder.query<ArticleLtysState, string>({
      providesTags: ['ArticleLtyList'],
      queryFn: backendQuery(() => ({
        url: 'article_lty',
      }))
    }),
    getArticleLtyBySid: builder.query<ArticleLtyState, string>({
      providesTags: ['ArticleLty'],
      queryFn: backendQuery(({ sid }) => ({
        url: 'article_lty/' + sid
      }))
    }),
    postArticleLty: builder.mutation({
      queryFn: backendQuery(() => ({
        url: "article_lty",
        method: "POST"
      }))
    }),
    putArticleLty: builder.mutation({
      queryFn: backendQuery(({ sid, data }) => ({
        url: "article_lty/" + sid,
        method: "PUT",
        body: {
          data
        }
      }))
    }),
    postArticleLtyPublish: builder.mutation({
      queryFn: backendQuery(({ sid }) => ({
        url: "article_lty/" + sid + '/publish',
        method: "POST"
      }))
    }),

    // auth, global API so no tenant stuff
    postUserLogin: builder.mutation({
      queryFn: backendQuery((credentials) => ({
        url: "auth/sign_in",
        method: "POST",
        body: credentials
      })),
      invalidatesTags: ['Policies', 'Article', 'Inbox', 'ReadMarks', 'UnreadCount', 'UserProfile', 'UserActivity' ],
      extraOptions: { skipAuthToken: true, noTenant: true }
    }),
    deleteUserLogout: builder.mutation({
      queryFn: backendQuery(() => ({
        url: "auth/sign_out",
        method: "DELETE"
      })),
      invalidatesTags: ['Policies', 'Article', 'Inbox', 'ReadMarks', 'UnreadCount', 'UserProfile', 'UserActivity' ],
      extraOptions: { noTenant: true }
    }),
    validateToken: builder.mutation({
      invalidatesTags: ['Policies', 'Article', 'Inbox', 'ReadMarks', 'UnreadCount'],
      queryFn: backendQuery(({ savedCurrentUser }) => ({
        url: 'auth/validate_token',
        headers: {
          'Access-Token': savedCurrentUser.token,
          'Token-Type': 'Bearer',
          'Client': savedCurrentUser.client,
          'Uid': savedCurrentUser.uid
        }
      })),
      extraOptions: { skipAuthToken: true, noTenant: true }
    }),
    postResetPassword: builder.mutation({
      queryFn: backendQuery(({ email }) => ({
        url: "auth/password",
        method: "POST",
        body: {
          email
        }
      })),
      extraOptions: { skipAuthToken: true, noTenant: true }
    }),
    putNewPassword: builder.mutation({
      queryFn: backendQuery(({ password, confirmation, currentUser }) => ({
        url: "auth/password",
        method: "PUT",
        body: {
          password,
          password_confirmation: confirmation
        },
        headers: {
          "Access-Token": currentUser.token,
          "Token-Type": 'Bearer',
          "Client": currentUser.client,
          "Uid": currentUser.uid
        }
      })),
      extraOptions: { skipAuthToken: true, noTenant: true }
    }),

    // bookmarks
    getBookmarks: builder.query({
      providesTags: ['Bookmarks'],
      queryFn: backendQuery(() => ({
        url: 'bookmarks'
      }))
    }),
    getBookmarksBySid: builder.query({
      providesTags: ['Bookmarks'],
      queryFn: backendQuery(({ sid }) => ({
        url: 'bookmarks/' + sid
      }))
    }),
    postBookmark: builder.mutation({
      invalidatesTags: ['Bookmarks'],
      queryFn: backendQuery(({ sid, tid }) => ({
        url: 'bookmarks',
        method: "POST",
        body: {
          sid,
          tid
        }
      }))
    }),
    deleteBookmark: builder.mutation({
      invalidatesTags: ['Bookmarks'],
      queryFn: backendQuery(({ sid, tid }) => ({
        url: "bookmarks/" + sid,
        method: "DELETE",
        body: {
          tid: tid
        }
      }))
    }),

    // hints
    postNewHintView: builder.mutation({
      queryFn: backendQuery(({ sid, pageViewId, closed }) => ({
        url: 'hint_views',
        method: "POST",
        body: {
          sid,
          page_view_id: pageViewId,
          closed
        }
      }))
    }),

    // policies
    getPolicies: builder.query({
      providesTags: ['Policies'],
      queryFn: backendQuery((configParams) => ({
        url: 'policies',
        params: configParams
      }))
    }),

    // read_marks
    getReadMarksBySid: builder.query<ReadMarksState, string>({
      providesTags: ['ReadMarks'],
      invalidatesTags: ['InvitedUsers'], // WHY??
      queryFn: backendQuery(({ sid }) => ({
        url: 'read_marks/' + sid
      }))
    }),
    postNewReadMarks: builder.mutation({
//      invalidatesTags: ['ReadMarks'],
      queryFn: backendQuery((readMarksProps) => ({
        url: 'read_marks',
        method: "POST",
        body: readMarksProps
      }))
    }),

    // scoped_lets
    getScopedLetsBySid: builder.query({
      providesTags: ['Article'],
      queryFn: backendQuery(({ sid }) => ({
        url: 'scoped_lets/' + sid
      })),
      extraOptions: {
        onComplete: (result, state) => {
          const { data } = result
          const currentUserId = state.currentUser.id

          if (currentUserId && data) {
            data.root_counts = calculateRootCountsFromRels(currentUserId, data.rel)
            data.first_hint_tid = calculateFirstHintTid(data)
          }

          return result
        }
      }
    }),
    postNewScopedLet: builder.mutation({
      invalidatesTags: ['Article'], // TODO: more granular invalidation, optimistic updates
      queryFn: backendQuery((letProps) => ({
        url: 'scoped_lets',
        method: "POST",
        body: letProps
      }))
    }),
    postScopedLetCloneFresh: builder.mutation({
      queryFn: backendQuery(({ sid, message }) => ({
        url: 'scoped_lets/' + sid + '/clone_fresh',
        method: "POST",
        body: { message }
      }))
    }),
    postScopedLetInviteUsers: builder.mutation({
      invalidatesTags: ['UserSearch', 'InvitedUsers'],
      queryFn: backendQuery(({ sid, user_ids }) => ({
        url: 'scoped_lets/' + sid + '/invite_users',
        method: "POST",
        body: { user_ids }
      }))
    }),
    getScopedLetInvitedUsersBySid: builder.query({
      providesTags: ['InvitedUsers'],
      queryFn: backendQuery(({ sid }) => ({
        url: 'scoped_lets/' + sid + '/invited_users'
      }))
    }),
    getScopedLetInvitationsBySid: builder.query({
      providesTags: ['ArticleInvitations'],
      queryFn: backendQuery(({ sid }) => ({
        url: 'scoped_lets/' + sid + '/invitations'
      }))
    }),

    // subscribed_lets
    getSubscribedLetsByUserId: builder.query<UserContributionsState, number>({
      providesTags: ['Inbox'],
      queryFn: backendQuery(({ userId }) => ({
        url: 'subscribed_lets/' + userId.toString()
      }))
    }),
    getUnreadCount: builder.query<number>({
      providesTags: ['UnreadCount'],
      queryFn: backendQuery(() => ({
        url: 'subscribed_lets/count',
      }))
    }),
    getOrgsUnreadCount: builder.query<number>({
      providesTags: ['UnreadCount'],
      queryFn: backendQuery(({ tenants }) => ({
        url: 'orgless/subscribed_lets/count',
        params: { tenants }
      })),
      extraOptions: { noTenant: true }
    }),
    putUpdateNotification: builder.mutation({
      invalidatesTags: ['Inbox', 'UnreadCount', 'ArticleInvitations'],
      queryFn: backendQuery(({ notificationIds, status }) => ({
        url: 'subscribed_lets/' + notificationIds.join(','),
        method: "PUT",
        body: { status }
      }))
    }),

    // uploads
    postUploadFile: builder.mutation({
      queryFn: backendQuery(({ dataBlob, filename, dirname }) => {
        const formData = new FormData()
        if (dirname) {
          formData.append('dirname', dirname)
        }
        if (dataBlob) {
          formData.append('file_data', dataBlob, filename)
        }

        return({
          url: 'uploads',
          method: "POST",
          body: formData
        })
      })
    }),


    // users
    getUserByNickname: builder.query<User, string>({
      providesTags: ['UserProfile'],
      queryFn: backendQuery(({ nickname }) => ({
        url: 'users/' + nickname
      }))
    }),
    getSearchUserByFor: builder.query({
      providesTags: ['UserSearch'],
      queryFn: backendQuery(({ forTerm, isInvitedSid }) => ({
        url: 'users/search',
        params: {
          for: forTerm,
          is_invited_sid: isInvitedSid
        }
      }))
    }),
    postCreateUser: builder.mutation({
      invalidatesTags: ['Policies', 'UserProfile'],
      queryFn: backendQuery((userProps) => ({
        url: 'users',
        method: "POST",
        body: userProps
      })),
      extraOptions: { skipAuthToken: true }
    }),
    postUpdateUser: builder.mutation({
      invalidatesTags: ['UserProfile'],
      queryFn: backendQuery((userProps) => ({
        url: 'users/' + userProps.id.toString(),
        method: "PATCH",
        body: userProps
      }))
    }),
    postUploadUserpic: builder.mutation({
      invalidatesTags: ['UserProfile'],
      queryFn: backendQuery(({ imageBlob, filename, invitationToken, email, resetImage }) => {
        const formData = new FormData()
        if (invitationToken) {
          formData.append('invitation_token', invitationToken)
        }
        if (email) {
          formData.append('email', email)
        }
        if (resetImage) {
          formData.append('reset_image', resetImage)
        }
        if (imageBlob) {
          formData.append('file_data', imageBlob, filename)
        }

        return({
          url: 'users/upload_userpic',
          method: "POST",
          body: formData
        })
      })
    }),

    // user_contributions
    getUserContributionsByUserId: builder.query<UserContributionsState, number>({
      providesTags: ['UserActivity'],
      queryFn: backendQuery(({ userId }) => ({
        url: 'user_contributions/' + userId.toString()
      }))
    })

  })
})

export async function waitToFinish(endpointName, store, arg) {
  store.dispatch(backendApi.endpoints[endpointName].initiate(arg))
  return await Promise.all(store.dispatch(backendApi.util.getRunningQueriesThunk()))
}

export async function updateReadMarks(sid, tids, postNewReadMarks) {
  try {
    const result = await postNewReadMarks({
      sid: sid,
      tids: tids
    })

    if (result.error) {
      return false
    } else {
      return true
    }
  } catch(err) {
    console.log(err)
  }
}

const showFirstHintAfterWordCount = 60

export function calculateFirstHintTid(data) {
  const { ref, blob }  = data
  const { head } = ref

  const headType = head.type
  const headTid = head.tid
  const articleLet = blob[headType][headTid]
  const paragraphTids = articleLet.paragraph_tids

  let totalWordCount = 0

  for (const paragraphTid of paragraphTids) {
    const paragraph = blob['Paragraph'][paragraphTid]
    const wc = paragraph.wc

    totalWordCount += wc

    if (totalWordCount > showFirstHintAfterWordCount) {
      return paragraphTid
    }
  }

  return paragraphTids.at(-1)
}

// For every root blob (article or sentence),
// calculate the total number of contributions
// that are created by other users but
// that are not footnotes
export function calculateRootCountsFromRels(currentUserId, rels) {
  const rootCounts = {}
  Object.keys(rels).forEach((tid) => {
    rels[tid].forEach((rel) => {
      const root_tid = rel.root_tid

      if (root_tid !== undefined) {
        const created_by = rel.created_by

        rootCounts[root_tid] ||= 0
        if ((created_by !== currentUserId) && (rel.type !== 'Footnote')) {
          rootCounts[root_tid]++
        }
      }
    })
  })

  return rootCounts
}

export const {
  // admin/invites
  useGetAdminInvitesQuery,
  usePostAdminInviteByEmailMutation,

  // articles_list
  useGetArticleListBySidQuery,
  usePostAddArticleToListMutation,

  // auth
  usePostUserLoginMutation,
  useDeleteUserLogoutMutation,
  useValidateTokenMutation, // mutation because of RTKQ bug which doesn't invalidate tags
  usePostResetPasswordMutation,
  usePutNewPasswordMutation,

  // article_lty
  useGetArticleLtyListQuery,
  useGetArticleLtyBySidQuery,
  usePostArticleLtyMutation,
  usePutArticleLtyMutation,
  usePostArticleLtyPublishMutation,

  // bookmarks
  useGetBookmarksQuery,
  useGetBookmarksBySidQuery,
  useLazyGetBookmarksBySidQuery,
  usePostBookmarkMutation,
  useDeleteBookmarkMutation,

  // hints
  usePostNewHintViewMutation,

  // policies
  useGetPoliciesQuery,
  useLazyGetPoliciesQuery,

  // read_marks
  useGetReadMarksBySidQuery,
  useLazyGetReadMarksBySidQuery,
  usePostNewReadMarksMutation,

  // scoped_lets
  useGetScopedLetsBySidQuery,
  usePostNewScopedLetMutation,
  usePostScopedLetCloneFreshMutation,
  usePostScopedLetInviteUsersMutation,
  useGetScopedLetInvitedUsersBySidQuery,
  useGetScopedLetInvitationsBySidQuery,

  // subscribed_lets
  useGetSubscribedLetsByUserIdQuery,
  useGetUnreadCountQuery,
  useGetOrgsUnreadCountQuery,
  usePutUpdateNotificationMutation,

  // uploads
  usePostUploadFileMutation,

  // users
  useGetUserByNicknameQuery,
  useLazyGetSearchUserByForQuery,
  usePostUpdateUserMutation,
  usePostCreateUserMutation,
  usePostUploadUserpicMutation,

  // user_contributions
  useGetUserContributionsByUserIdQuery
} = backendApi

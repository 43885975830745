import { useAppSelector, useAppDispatch } from "../hooks"
import { setCurrentUser, selectCurrentUser } from "../renderer/currentUserSlice"
import { useRef } from "react"
import { usePageContext } from "../renderer/usePageContext"
import { useOnClickOutside } from "../lib/ReactUtil"
import { useDeleteUserLogoutMutation } from "../services/backendApi"
import { envURL } from "../lib/urlHelpers"
import { IfAllowed, FeatureOption } from "../lib/Auth"
import classNames from "classnames"
import { useTranslate } from "@tolgee/react"

function MenuLink({ title, href, accented }) {
//    <a onClick={(e) => {e.preventDefault(); window.location.href = href}} href={href}><div>{title}</div></a>
  return(
    <a className={classNames({ accented })} href={href}><div>{title}</div></a>
  )
}

function HiddenMenu({ isExpanded, setIsExpanded, onLogout, badgeCount }) {
  const { t } = useTranslate()
  const pageContext = usePageContext()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(selectCurrentUser)
  const onCloseRef = useRef(null)
  const [deleteUserLogout, { isLoading, error }] = useDeleteUserLogoutMutation()

  useOnClickOutside(onCloseRef, (event, isExpanded) => {
    if (isExpanded) {
      setIsExpanded(false)
    }
  }, isExpanded)

  if (!currentUser.id) {
    return null
  }

  const onClickLogout = async () => {
    try {
      const result = await deleteUserLogout()

      if (result.data && result.data.success) {
        onLogout()

        setIsExpanded(false)
      } else {
        console.error("Couldn't log out")
      }
    } catch(err) {
      console.log(err)
    }
  }

  const inboxAccented = (badgeCount > 0)
  const inboxTitle = inboxAccented ? `Inbox (${badgeCount})` : t("user-menu.link.inbox", "Inbox")

  const tenant = pageContext.tenant
  const inboxLink = envURL(tenant, '/inbox')
  const writingLink = envURL(tenant, '/writing')
  const userProfileLink = envURL(tenant, '/user/' + currentUser.nickname)
  const invitesLink = envURL(tenant, '/invites')
  const uploadsLink = envURL(tenant, '/upload-docs')
  const bookmarksLink = envURL(tenant, '/bookmarks')

  return(
    <div className={classNames("hidden-menu", { expanded: isExpanded })}>
      <ul ref={onCloseRef}>
        <li>
          <MenuLink title={inboxTitle} href={inboxLink} accented={inboxAccented} />
        </li>
        <IfAllowed klass="ArticleLty" action="access">
          <li>
            <MenuLink title={t("user-menu.link.my-posts", "My Posts")} href={writingLink} />
          </li>
        </IfAllowed>
        <li>
          <MenuLink title="User Profile" href={userProfileLink} />
        </li>
        <IfAllowed klass="User" action="list_invited">
          <li>
            <MenuLink title={currentUser.admin ? "Invited People" : "Send Invites"} href={invitesLink} />
          </li>
        </IfAllowed>
        <IfAllowed klass="User" action="upload_docs">
          <li>
            <MenuLink title="Upload Docs" href={uploadsLink} />
          </li>
        </IfAllowed>
        { currentUser.beta && <li><MenuLink title="Bookmarks" href={bookmarksLink} /></li> }
        <FeatureOption feature="eng_intern" optionVariant={true}>
          <li>
            <a onClick={(e) => {e.preventDefault(); throw('test error!')}}><div>[INTERN]: Error!</div></a>
          </li>
          <li>
            <a onClick={(e) => {e.preventDefault(); Promise.reject(new Error('fail'))}}><div>[INTERN]: Reject!</div></a>
          </li>
        </FeatureOption>
        <li>
          <a onClick={onClickLogout}><div>Log Out</div></a>
        </li>
      </ul>
    </div>
  )
}

export default HiddenMenu

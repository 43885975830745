import { configureStore, ThunkAction, Action, createListenerMiddleware } from "@reduxjs/toolkit"

import { combineReducers } from 'redux'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import currentUserSlice from './currentUserSlice'
import uiSlice from './uiSlice'
import pageContextSlice from './pageContextSlice'
import policiesSlice from "./policiesSlice"
import { backendApi } from '../services/backendApi'
import { parseTokenHeaders, saveClientToken } from "../services/backend"

export { getStore }

const listenerMiddleware = createListenerMiddleware()

// When currentUser.token changes in Redux state, save updated user info to localStorage
listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    if ((action.origin === 'storage') || (action.origin === 'signup')) {
      return false
    }

    return (currentState.currentUser.token !== previousState.currentUser.token)
  },
  effect: (action, listenerApi) => {
    const state = listenerApi.getState()

    saveClientToken(state.currentUser)
  }
})

function getStore(PRELOADED_STATE) {
  const store = configureStore({
    reducer: {
      currentUser: currentUserSlice,
      ui: uiSlice,
      pageContext: pageContextSlice,
      policies: policiesSlice,
      [backendApi.reducerPath]: backendApi.reducer,
    },
    preloadedState: PRELOADED_STATE,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(
        listenerMiddleware.middleware
      ).concat(
        backendApi.middleware
      )
  })

  setupListeners(store.dispatch)

  return store
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
